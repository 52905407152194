h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  align-items: stretch !important;
  display: flex !important;
  background-color: black;
  
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
  color: white;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}
/*style for Dashboard*/
div.dashbd {
  border: 1px solid black;
  height:100%
}

/*Style for Dataportal*/
#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 850px;
  height: 600px;
  font-family: sans-serif;
  margin: 50px auto;
  padding: 20px;
  }
#drop-area.highlight {
  border-color: purple;
}
p {
  margin-top: 0;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
   display: none;
}
.percent {
  font-weight: bold;
}
div.border {
  border: 1px solid black;
}